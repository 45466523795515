@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-button {
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  padding: 6px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #555;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.menu {
  display: flex;
  list-style: none;
}

.menu li {
  margin-left: 10px;
}

.menu li a {
  text-decoration: none;
  color: #333;
}

.login-form,
.signup-form,
.user-settings,
.post-form {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-form h2,
.signup-form h2,
.user-settings h2,
.post-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.login-form label,
.signup-form label,
.user-settings label,
.post-form label {
  display: block;
  margin-bottom: 5px;
}

.login-form input,
.signup-form input,
.user-settings input[type='text'],
.user-settings input[type='email'],
.user-settings textarea,
.post-form input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.login-form button,
.signup-form button,
.user-settings button,
.post-form button {
  width: 100%;
  padding: 8px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.app {
  text-align: center;
  margin-top: 40px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.photo {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.photo:hover {
  transform: scale(1.05);
}

.photo img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

.photo p {
  margin-top: 10px;
  font-size: 16px;
}

.user-settings {
  max-width: 400px;
  margin: 0 auto;
}

.user-settings progress {
  width: 100%;
  height: 10px;
  margin-top: 5px;
}

.buttons-container {
  align-items: center;
  justify-content: left;
  display: flex;
}

.profile-initials {
  width: 30px;
  height: 30px;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}